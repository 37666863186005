import home from '../../assets/home.jpg';
import qualityIcon from '../../assets/quality.png';
import priceIcon from '../../assets/price.png';
import ship from '../../assets/ship.png';
import noImage from '../../assets/no-image.jpg';

const images = {
    home,
    qualityIcon,
    priceIcon,
    ship,
    noImage
}

export default images;